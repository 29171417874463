import Alpine from "alpinejs";
import { debounce, find, get } from "lodash-es";
import $ from "jquery";
import { getUserPlatform } from "~/scripts/utils";

export const initProduct = () => {
  // First, check if this component has to be initialized
  if (hasProductPage()) {
    // then, fire it up!
    start();
  }
};

/**
 * Checks if page has autocomplete component
 * @return {Boolean}
 */
const hasProductPage = () => {
  return document.getElementsByClassName("product").length > 0;
};

// Start our component
const start = () => {
  const renderResponsiveVideo = () => {
    const $videos = Array.from(
      document.querySelectorAll('iframe[src*="youtube"]')
    );

    if ($videos.length > 0) {
      $videos.forEach(($video) => {
        $video.setAttribute("width", "100%");
        $video.setAttribute("height", $video.offsetWidth * 0.5625);
      });
    }
  };

  renderResponsiveVideo();

  window.addEventListener("resize", debounce(renderResponsiveVideo), 200);

  // $.each($videoIframes, (v) => {
  //   const $videoIframe = $(v);
  //   console.log("$videoIframe", $videoIframe);

  //   var responsiveHeight = $videoIframe.parent().offsetWidth() * 0.5625;
  //   $videoIframe.width("100%");
  //   $videoIframe.height(responsiveHeight);
  // });

  // //브라우저 리사이즈 시 iframe 높이값 비율에 맞게 세팅
  // window.addEventListener("resize", function () {
  //   responsiveHeight = $videoIframe.parent().offsetWidth() * 0.5625;
  //   $videoIframe.width("100%");
  //   $videoIframe.height(responsiveHeight);
  // });

  const getSelectedVariants = (product, type = "default") => {
    let selectedVariants = [];

    switch (type) {
      case "radio": {
        let item;
        const search = new URLSearchParams(window.location.search);
        const searchedVariant = search.get("variant");

        if (product.variants.length === 1) {
          item = product.variants[0];
        }

        if (searchedVariant) {
          item = find(
            product.variants,
            (object) => parseInt(object.id) === parseInt(searchedVariant)
          );
        }

        if (item) {
          selectedVariants.push({ ...item, count: 1 });
        }

        break;
      }
      default:
        if (product.variants.length === 1) {
          const variant = get(product, "variants.0", null);

          if (variant) {
            selectedVariants.push({ ...variant, count: 1 });
          }
        }

        break;
    }

    return selectedVariants;
  };

  Alpine.data("product", () => ({
    data: null,
    hasOnlyDefaultVariant: true,
    showVariants: false,
    selectedVariants: [],
    selectedVariantsSubtotal: 0,
    // tab: "details",
    selectType: "default",
    option1: null,
    option2: null,
    option3: null,
    firstAvailableVariantId: null,
    inventoryQuantity: null,
    stockMax: null,
    stockMin: null,
    plusDiscount: false,
    plusDiscountRate: 0,
    init() {
      const product = JSON.parse(this.$el.dataset.product);
      this.plusDiscount = this.$el.dataset.plusDiscount == "true";
      this.plusDiscountRate = this.$el.dataset.plusDiscountRate;
      this.stockMax = this.$el.dataset.stockMax;
      this.stockMin = this.$el.dataset.stockMin;
      this.firstAvailableVariantId = this.$el.dataset.firstAvailableVariantId;
      this.inventoryQuantity = this.$el.dataset.inventoryQuantity;

      const selectType = product.type === "Gift Cards" ? "radio" : "default";
      const selectedVariants = getSelectedVariants(product, selectType);

      if (selectedVariants.length > 0) {
        this.option1 = get(selectedVariants, "0.option1", null);
        this.option2 = get(selectedVariants, "0.option2", null);
        this.option3 = get(selectedVariants, "0.option3", null);
      }

      this.$watch("selectedVariants", (selectedVariants) => {
        if (selectedVariants && selectedVariants.length > 0) {
          let selectedVariantsSubtotal = selectedVariants.reduce(
            (a, b) => a + b.price * b.count,
            0
          );

          if (this.plusDiscount) {
            selectedVariantsSubtotal =
              selectedVariantsSubtotal * (1 - this.plusDiscountRate / 100);
          }

          this.selectedVariantsSubtotal = selectedVariantsSubtotal;
        }
      });

      this.data = product;
      this.hasOnlyDefaultVariant = JSON.parse(
        this.$el.dataset.hasOnlyDefaultVariant
      );
      this.selectedVariants = selectedVariants;

      // // 상품 리뷰 클릭하면 리뷰 탭으로 전환
      // $(".review-stars > .jdgm-wooltari").on("click", (event) => {
      //   event.preventDefault();

      //   this.tab = "reviews";
      // });

      // // TAB이 변할 때
      // this.$watch("tab", (value) => {
      //   $([document.documentElement, document.body]).animate(
      //     {
      //       scrollTop: $("#tabs").offset().top - $(".site-header").height(),
      //     },
      //     500
      //   );
      // });

      // 옵션 감지
      this.$watch("option1", () => this.updateSelection());
      this.$watch("option2", () => this.updateSelection());
      this.$watch("option3", () => this.updateSelection());

      // console.log("INIT ALPINE PRODUCT");
    },

    // setTab(key) {
    //   this.tab = key;
    // },
    reset() {
      this.showVariants = false;
      //   this.selectedVariants = this.getSelectedVariants(this.selectType);
    },
    addVariant(variantId) {
      const selectedVariantIndex = this.selectedVariants.findIndex(
        (item) => parseInt(item.id) === parseInt(variantId)
      );
      const hasVariant = selectedVariantIndex > -1;

      if (variantId === "none") {
        return;
      }

      $(".variant-picker").val("none");

      if (hasVariant) {
        return Alpine.store("toast").create("이미 추가된 상품입니다.");
      }

      if (variantId !== "none") {
        const index = this.data.variants.findIndex(
          (item) => parseInt(item.id) === parseInt(variantId)
        );

        if (index > -1) {
          const item = this.data.variants[index];

          this.selectedVariants.push({ ...item, count: 1 });
        }
      }
    },
    updateSelection() {
      const selectedVariant = this.data.variants.find((variant) => {
        let match = true;
        if (variant.option1 != "") {
          match = variant.option1 == this.option1;
        }
        if (match && variant.option2 != "") {
          match = variant.option2 == this.option2;
        }
        if (match && variant.option3 != "") {
          match = variant.option3 == this.option3;
        }
        return match;
      });

      this.selectedVariants = [
        {
          ...selectedVariant,
          count: 1,
        },
      ];

      const search = new URLSearchParams(window.location.search);
      search.set("variant", selectedVariant.id);
      window.history.replaceState(
        undefined,
        undefined,
        `?${search.toString()}`
      );
    },
    removeVariant(variantId) {
      const selectedVariantIndex = this.selectedVariants.findIndex(
        (item) => parseInt(item.id) === parseInt(variantId)
      );
      const hasVariant = selectedVariantIndex > -1;

      if (hasVariant) {
        this.selectedVariants.splice(selectedVariantIndex, 1);
      }
    },
    plusVariantCount(variantId) {
      const index = this.selectedVariants.findIndex(
        (item) => parseInt(item.id) === parseInt(variantId)
      );

      if (index > -1) {
        const item = this.selectedVariants[index];

        this.selectedVariants[index] = {
          ...item,
          count: item.count + 1,
        };
      }
    },
    minusVariantCount(variantId) {
      const index = this.selectedVariants.findIndex(
        (item) => parseInt(item.id) === parseInt(variantId)
      );

      if (index > -1) {
        const item = this.selectedVariants[index];

        this.selectedVariants[index] = {
          ...item,
          count: item.count - 1,
        };
      }
    },
    toggleVariants() {
      this.showVariants = !this.showVariants;
    },
    async addToCart() {
      const items = [];

      if (this.selectedVariants.length === 0) {
        const message = window.$t("cart.please_select_variants");

        Alpine.store("toast").create(message);
        return false;
      }

      const customerStore = Alpine.store("customer");
      const email = customerStore?.email;

      const eventVariantIds = [];
      const eventQuantities = [];
      const twtitterContents = [];
      let totalPrice = 0;

      this.selectedVariants.forEach((item) => {
        const variantIndex = this.data.variants.findIndex(
          (variant) => variant.id === item.id
        );
        if (variantIndex !== -1) {
          const variant = this.data.variants[variantIndex];
          totalPrice = variant.price * item.count + totalPrice;
          twtitterContents.push({
            content_type: "product",
            content_id: `${item.id}`,
            content_name: `${this.data.title}`,
            content_price: variant.price * item.count,
            num_items: item?.count ? parseFloat(item.count) : 0,
          });
        }

        items.push({
          id: item.id.toString(),
          quantity: item.count.toString(),
          tags: this.data.tags,
          metafields: {
            stock_max: this.stockMax,
            stock_min: this.stockMin,
          },
        });
        eventVariantIds.push(item.id);
        eventQuantities.push(item.count);
      });

      const addToCartProperties = {
        product_id: this.data.id,
        title: this.data.title,
        handle: this.data.handle,
        type: this.data.type,
        tags: this.data.tags,
        price: this.data.price,
        compare_at_price: this.data.compare_at_price,
        available: this.data.available,
        first_available_variant_id: this.firstAvailableVariantId,
        inventory_quantity: this.inventoryQuantity,
        variant_ids: eventVariantIds,
        quantities: eventQuantities,
        platform: `${getUserPlatform()}`,
      };

      window?.mixpanel.track("add_to_cart", addToCartProperties);

      window.messageBridge.track("add_to_cart", {
        value: totalPrice,
        contents: twtitterContents,
        currency: "USD",
        email_address: email ? email : null,
      });

      window.twq("event", "tw-od7mn-od7mx", {
        value: totalPrice,
        contents: twtitterContents,
        currency: "USD",
        email_address: email ? email : null,
      });

      Alpine.store("cart").addItems(items, () => this.reset());
    },
  }));
};
