import Alpine from "alpinejs";
import {
  includes,
  isNull,
  get,
  map,
  merge,
  find,
  filter,
  groupBy,
} from "lodash-es";
import messageBridge from "./utils/messageBridge";

const MINIMUM_SUBTOTAL_FOR_WELCOME_GIFT = 12900;

export const initCart = () => {
  start();
};

const isWelcomeGift = (item) =>
  item?.tags && includes(item.tags, "new-customer");

const hasStockMax = (item) =>
  !isNull(get(item, "metafields.stock_max", null)) &&
  parseInt(get(item, "metafields.stock_max", 0)) !== 0;

const hasStockMin = (item) => !isNull(get(item, "metafields.stock_min", null));

const isFreeItem = (item) => {
  if (item.product_type === "Gift Cards") {
    return true;
  }
  if (item?.tags && includes(item.tags, "pkg-free")) {
    return true;
  }
};

export const hasOnlyRoomLines = (lines) => {
  if (!lines && lines.length === 0) {
    return false;
  }
  let hasOnlyRoomItems = false;

  const roomItems =
    lines.filter((line) => {
      return line?.tags && line.tags.includes("pkg-room");
    }) || [];

  const roomItemsCount = roomItems.length;

  if (lines.length === roomItemsCount) {
    hasOnlyRoomItems = true;
  }

  return hasOnlyRoomItems;
};

const getFastItems = (items) => {
  return items.filter((item) => !isFreeItem(item));
};

const getFreeItems = (items) => {
  return items.filter((item) => isFreeItem(item));
};

export const isRoomProduct = (item) => {
  let room = false;

  if (item.tags.includes("pkg-room")) {
    room = true;
  }

  if (item.product_type === "Gift Cards") {
    room = false;
  }

  return room;
};
export const isFreshProduct = (item) => {
  let fresh = true;

  if (item.tags.includes("pkg-room")) {
    fresh = false;
  }
  if (item.product_type === "Gift Cards") {
    fresh = false;
  }

  return fresh;
};

export const isGiftCards = (item) => {
  let gift = false;

  if (item.product_type === "Gift Cards") {
    gift = true;
  }

  return gift;
};

window.isFreshProduct = isFreshProduct;
window.isRoomProduct = isRoomProduct;
window.isGiftCards = isGiftCards;

const fetchCart = async (data) => {
  const requestCart = fetch(`${window.Shopify.routes.root}cart.js`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => response.json());

  const requestAdditional = fetch("/cart?view=wooltari", {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => response.json());

  const [cartResponse, additionalResponse] = await Promise.all([
    requestCart,
    requestAdditional,
  ]);

  let payload = {};

  if (cartResponse && additionalResponse) {
    const cartData = cartResponse;
    const additional = additionalResponse;

    payload = {
      ...cartData,
      items: map(cartData.items, (item) => {
        return merge(item, find(additional, { id: item.id }));
      }),
    };
  }

  return payload;
};

const start = () => {
  initGiftCard();

  Alpine.store("cart", {
    // addModal: {
    //   isOpen: false,
    //   props: null,
    // },
    // openAddModal(props) {
    //   this.addModal.isOpen = true;
    //   this.addModal.props = props;
    // },
    // closeAddModal() {
    //   this.addModal.isOpen = false;
    //   this.addModal.props = null;
    //   this.quick = null;
    // },
    async quickAdd($el) {
      const handle = $el.dataset?.handle;

      if (!handle) return;

      const stockMax = $el.dataset?.max ? parseInt($el.dataset.max) : 0;
      const stockMin = $el.dataset?.min ? parseInt($el.dataset.min) : 0;
      const quantity = $el.dataset.quantity;
      const type = $el.dataset.type;
      const id = $el.dataset.id;
      const title = $el.dataset.title;
      const price = $el.dataset.price;
      const compare_at_price = $el.dataset?.compareAtPrice;
      const tags = $el.dataset?.tags ? JSON.parse($el.dataset.tags) : [];
      const variants = $el.dataset?.variants
        ? JSON.parse($el.dataset.variants)
        : [];

      if (variants.length === 1) {
        this.quick = `${handle}`;

        Alpine.store("cart").addItems([
          {
            id: get(variants, "0.id"),
            quantity: 1,
            tags: tags,
            metafields: { stock_max: stockMax, stock_min: stockMin },
          },
        ]);

        const customerStore = Alpine.store("customer");
        const email = customerStore?.email;

        const eventVariantIds = [`${get(variants, "0.id")}`];
        const eventQuantities = [1];

        const twtitterContents = [
          {
            content_type: "product",
            content_id: `${get(variants, "0.id")}`,
            content_name: `${title}`,
            content_price: get(variants, "0.price"),
            num_items: 1,
          },
        ];

        const addToCartProperties = {
          product_id: id,
          title: title,
          handle: handle,
          type: type,
          tags: tags,
          price: price,
          compare_at_price: compare_at_price,
          // first_available_variant_id: this.firstAvailableVariantId,
          // inventory_quantity: this.inventoryQuantity,
          variant_ids: eventVariantIds,
          quantities: eventQuantities,
        };

        window?.mixpanel.track("add_to_cart", addToCartProperties);

        window.messageBridge.track("add_to_cart", {
          value: get(variants, "0.price"),
          contents: twtitterContents,
          currency: "USD",
          email_address: email ? email : null,
        });

        window.twq("event", "tw-od7mn-od7mx", {
          value: get(variants, "0.price"),
          contents: twtitterContents,
          currency: "USD",
          email_address: email ? email : null,
        });
      } else {
        Alpine.store("variantsModal").open(handle);
      }
    },
    quick: null,
    changeFetcher: null,
    isRoomCart: false,
    isInit: false,
    isSubmitting: false,
    submittingVariantIds: [],
    errors: null,
    data: null, //Alpine.$persist(null).using(sessionStorage),
    quantities: null,
    shippingRate: 0,
    minOrderSubtotal: 0,
    freshMinOrderSubtotal: 0,
    roomMinOrderSubtotal: 0,
    welcomeGiftSubtotal: 0,
    totalSubtotal: 0,
    totalShippingRate: 0,
    totalPlusDiscountSubtotal: 0,
    deliveryMethod: {
      "pkg-fast": {
        items: [],
        subtotal: 0,
        shippingRate: 0,
        ui: {
          progress: "0%",
        },
      },
      "pkg-free": {
        items: [],
        subtotal: 0,
        shippingRate: 0,
      },
    },

    async loadCart({
      data: origin,
      shippingRate,
      roomShippingRate,
      minOrderSubtotal,
      roomMinOrderSubtotal,
      quantities,
    }) {
      let data = await fetchCart();

      if (!this.isInit) {
        if (window.MobileDetect.isApp) {
          const legacyPropertiesItems = data.items.filter((item) => {
            const isApp = get(item, "properties.app", false);

            return !isApp;
          });

          if (legacyPropertiesItems && legacyPropertiesItems.length > 0) {
            // Synchronziation
            for (const item of legacyPropertiesItems) {
              await fetch(`/cart/change.js`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  id: item.key,
                  quantity: 0,
                  // properties: isEmpty(nextProperties) ? {} : nextProperties,
                }),
              });
            }
            data = await fetchCart();
          }
        }

        this.data = data;
        this.quantities = quantities; // SOLD OUT TEST { ...quantities, 40285810950321: 0 };
        this.shippingRate = shippingRate;
        this.roomShippingRate = roomShippingRate;
        this.minOrderSubtotal = minOrderSubtotal;
        this.freshMinOrderSubtotal = minOrderSubtotal;
        this.roomMinOrderSubtotal = roomMinOrderSubtotal;
        this.isInit = true;
      }
    },

    verifyAddItems(items) {
      const customerStore = Alpine.store("customer");

      // 사용자가 장바구니 담기 요청한 신규회원 혜택상품 가져오기
      const payloadWelcomeItems = items.filter((item) => isWelcomeGift(item));

      if (payloadWelcomeItems.length > 0) {
        // 사용자가 장바구니 담기 요청한 신규회원 혜택 상품이 있을 때
        if (!customerStore.id) {
          // 비로그인시
          window.location.href = "/account/login";

          return false;
        }

        // welcome-gift 에서 orders_count 로 변경
        if (customerStore.orders_count != 0) {
          // 사용자의 태그를 가져올 수 없거나, 신규회원이 아닐 때
          Alpine.store("toast").create(window.$t("cart.invalid_welcome_user"));
          return false;
        }

        // 장바구니에 담겨있는 신규회원 혜택 상품 가져오기
        const welcomeItemsInCart = this.data.items.filter((item) =>
          isWelcomeGift(item)
        );

        if (welcomeItemsInCart.length >= 2) {
          // 장바구니에 담겨있는 신규회원 혜택 상품의 갯수
          Alpine.store("toast").create(
            window.$t("cart.invalid_welcome_product_quantity")
          );

          return false;
        }

        let hasOverQty = false;

        payloadWelcomeItems.forEach((payloadItem) => {
          const already = welcomeItemsInCart.filter(
            (item) => parseInt(item.id) === parseInt(payloadItem.id)
          );

          if (hasOverQty) {
            return false;
          }

          if (already.length > 0) {
            hasOverQty = true;
          }
        });

        if (hasOverQty) {
          Alpine.store("toast").create(
            window.$t("cart.invalid_welcome_quantity")
          );
          return false;
        }

        return true;
      }

      const payloadHasStockMax = items.filter((item) => hasStockMax(item));

      // Alpine.store("snackbar").add({type: 'success', text: '🔥 Success!'})
      // 사용자가 장바구니 담기 요청한 상품에 최대 구매 수량 제한이 있을 때
      let isValidStockMax = true;

      if (payloadHasStockMax.length > 0) {
        payloadHasStockMax.forEach((item) => {
          // 요청한 상품의 갯수 확인
          if (
            parseInt(item.metafields.stock_max) !== 0 &&
            parseInt(item.quantity) > parseInt(item.metafields.stock_max)
          ) {
            Alpine.store("toast").create(
              window.$t("cart.invalid_max_order_quantity", {
                count: item.metafields.stock_max,
              })
            );

            isValidStockMax = false;
            return false;
          }

          // 동일한 상품이 장바구니에 담겨 있는 지 확인
          const sameVariantItems = filter(
            this.data.items,
            (o) => parseInt(o.variant_id) === parseInt(item.id)
          );

          if (sameVariantItems.length > 0) {
            // sameVariantItems.forEach(sameVariantItem => {
            const payloadQuantities = payloadHasStockMax.reduce(
              (a, b) => a + parseInt(b.quantity),
              0
            );
            const sameVariantItemsQuantities = sameVariantItems.reduce(
              (a, b) => a + parseInt(b.quantity),
              0
            );

            if (
              parseInt(item.metafields.stock_max) !== 0 &&
              parseInt(payloadQuantities) +
                parseInt(sameVariantItemsQuantities) >
                parseInt(item.metafields.stock_max)
            ) {
              const message = window.$t("cart.has_invalid_max_order_quantity", {
                count: item.metafields.stock_max,
              });

              Alpine.store("toast").create(message);

              isValidStockMax = false;
              return false;
            }
            // });
          }
        });
      }

      if (!isValidStockMax) {
        return false;
      }

      return true;
    },

    /* 
      장바구니 담기
    */
    async getCart() {
      const response = await fetchCart();

      this.data = response;

      return response;
    },
    addItems(items, callback, errorCallback) {
      messageBridge.vibration();

      if (this.isFetching) {
        return;
      }

      const isValid = this.verifyAddItems(items);

      if (!isValid) {
        this.quick = null;
        errorCallback && errorCallback();

        return;
      }

      /* s
        중요:
        새로운 테마 개발 과정에서 과거 테마 사용자가 담은 장바구니에 대해 Property 값이 달라 장바구니 아이템이 분리되는 현상을 정리하기 위해 
        property를 Sync 하는 로직을 추가함. 본래는 속성값이 아닌 메타필드를 바라보고 문제를 해결해야하는 부분임. 
      */
      let payload = [];
      let tags = "";

      if (get(Alpine.store("customer"), "tags", null)) {
        tags = Alpine.store("customer").tags.join(",");
      }

      items.forEach((item) => {
        let itemPayload = { ...item };

        if (window.MobileDetect.isApp) {
          itemPayload = { ...item, properties: { app: true } };
        }

        payload.push(itemPayload);
      });

      this.isSubmitting = true;
      this.submittingVariantIds = items.map((item) => item.id);

      fetch("/cart/add.js", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: payload, sections: ["cart-badge"] }),
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response?.sections && response?.sections["cart-badge"]) {
            const cartBadge = response.sections["cart-badge"];

            // select all .cart-badge and update innerHTML
            const badgeElements = document.querySelectorAll(".cart-badge");

            badgeElements.forEach((badgeElement) => {
              badgeElement.innerHTML = cartBadge;
            });
          }

          if (response?.status === 422) {
            Alpine.store("toast").create(response.description);
            this.isSubmitting = false;
            this.quick = null;

            errorCallback && errorCallback();
            return;
          }

          if (response) {
            Alpine.store("toast").create(window.$t("cart.added_item"), "cart");
            this.isSubmitting = false;
            this.quick = null;
            this.submittingVariantIds = [];

            const cartData = await fetchCart();
            this.data = cartData;

            callback && callback();
          }
        });
    },

    verifyChangeItem(key, quantity) {
      const item = find(this.data.items, (item) => item.key === key);

      if (item) {
        if (item.quantity < quantity) {
          // 추가 할 때
          const payloadItem = {
            ...item,
            quantity: quantity - item.quantity,
          };
          const isValid = this.verifyAddItems([payloadItem]);

          return isValid;
        } else {
          // 제거 할 때
        }
      }
      return true;
    },

    /* 
      장바구니 line_item 수량 변경
    */
    changeItem(id, quantity = 1) {
      messageBridge.vibration();
      const isValid = this.verifyChangeItem(id, quantity);

      if (!isValid) {
        return false;
      }

      this.changeFetcher = id;

      fetch("/cart/change.js", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id,
          quantity,
          sections: ["cart-badge"],
        }),
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response?.sections && response?.sections["cart-badge"]) {
            const cartBadge = response.sections["cart-badge"];

            const badgeElements = document.querySelectorAll(".cart-badge");

            badgeElements.forEach((badgeElement) => {
              badgeElement.innerHTML = cartBadge;
            });
          }

          const cartData = await fetchCart();
          this.changeFetcher = null;
          this.data = cartData;
        });
    },

    clearCart() {
      if (window.confirm(window.$t(`cart.confirm_clear_cart`))) {
        fetch("/cart/clear.js", { method: "POST" }).then(
          (response) => response.ok && location.reload()
        );
      }
    },
  });

  Alpine.effect(() => {
    const cartStore = Alpine.store("cart");
    const customerStore = Alpine.store("customer");

    const data = cartStore.data;
    const deliveryMethod = cartStore.deliveryMethod;
    const shippingRate = cartStore.shippingRate;
    const roomShippingRate = cartStore.roomShippingRate;
    let minOrderSubtotal = cartStore.minOrderSubtotal;
    const freshMinOrderSubtotal = cartStore.freshMinOrderSubtotal;
    const roomMinOrderSubtotal = cartStore.roomMinOrderSubtotal;
    const quantities = cartStore.quantities;
    let isRoomCart = false;
    const errors = [];

    if (data?.items.length > 0) {
      let discountSubtotal = 0;
      data.items.forEach((item) => {
        if (item?.discounts && item.discounts.length > 0) {
          const itemPlusDiscountsSubtotal = item.discounts.reduce(
            (a, b) => a + b.amount,
            0
          );

          discountSubtotal += itemPlusDiscountsSubtotal;
        }
      });
      Alpine.store("cart").totalPlusDiscountSubtotal = discountSubtotal;

      if (data.items.filter((item) => isWelcomeGift(item)).length > 0) {
        // console.log("// 신규회원 상품이 있을 때");
        if (customerStore.id === null) {
          // console.log("// 비로그인 회원일 때 ");
          errors.push({
            message: window.$t("cart.invalid_welcome_user_permission"),
          });
        } else {
          // console.log("// 로그인 했을 때 ");
          // welcome-gift 에서 orders_count 로 변경
          if (customerStore.orders_count != 0) {
            // console.log("// 신규회원이 아닌 경우");
            errors.push({
              message: window.$t("cart.invalid_welcome_user"),
            });
          } else {
            // console.log("// 신규회원인 경우");
            // console.log("// 신규회원 상품이 2개 이상일 때");
            if (data.items.filter((item) => isWelcomeGift(item)).length > 2) {
              // console.log("// 신규회원 상품 수량이 2개 이상일 때 ");
              errors.push({
                message: window.$t("cart.invalid_welcome_product_quantity"),
              });
            }
            if (
              data.items
                .filter((item) => isWelcomeGift(item))
                .filter((item) => item.quantity > 1).length >= 1
            ) {
              // console.log("// 신규회원 상품 구매 최소 수량을 넘었을 때");
              errors.push({
                message: window.$t("cart.invalid_welcome_quantity"),
              });
            }

            if (
              data.items
                .filter((item) => !isWelcomeGift(item))
                .reduce((a, b) => a + b.original_line_price, 0) >=
              MINIMUM_SUBTOTAL_FOR_WELCOME_GIFT
            ) {
              // console.log("// 상품 금액 합이 100이상일 때");
            } else {
              // console.log("// 상품 금액 합이 100미만일 때");
              errors.push({
                message: window.$t("cart.invalid_welcome_min_subtotal"),
              });
            }
          }
        }
      }
      // 최소 및 최대 주문수량 확인
      if (data.items.filter((item) => hasStockMax(item)).length > 0) {
        const hasStockMaxitems = data.items.filter((item) => hasStockMax(item));

        // 동일 제품군 확인
        const hasStockMaxItemsById = groupBy(hasStockMaxitems, "id");

        if (Object.keys(hasStockMaxItemsById).length > 0) {
          Object.keys(hasStockMaxItemsById).forEach((id) => {
            const quantities = hasStockMaxItemsById[id].reduce(
              (a, b) => a + b.quantity,
              0
            );

            if (
              hasStockMaxItemsById[id][0].metafields.stock_max !== 0 &&
              quantities > hasStockMaxItemsById[id][0].metafields.stock_max
            ) {
              errors.push({
                message: window.$t(
                  "cart.invalid_max_order_quantity_with_title",
                  {
                    title: hasStockMaxItemsById[id][0].product_title,
                    count: hasStockMaxItemsById[id][0].metafields.stock_max,
                  }
                ),
              });
            }
          });
        } else {
          hasStockMaxitems.forEach((item) => {
            if (
              item.metafields.stock_max !== 0 &&
              item.quantity > item.metafields.stock_max
            ) {
              // console.log("// 최대구매수량 이상 일 때");
              errors.push({
                message: window.$t(
                  "cart.invalid_max_order_quantity_with_title",
                  {
                    title: item.title,
                    count: item.metafields.stock_max,
                  }
                ),
              });
            }
          });
        }
      }
      if (data.items.filter((item) => hasStockMin(item)).length > 0) {
        // console.log(
        //   "// 최소구매수량이 있는 상품",
        //   data.items.filter((item) => hasStockMin(item))
        // );
        const hasStockMinitems = data.items.filter((item) => hasStockMin(item));

        hasStockMinitems.forEach((item) => {
          if (item.quantity < item.metafields.stock_min) {
            // console.log("// 최소주문수량 이하 일 때");
            errors.push({
              message: window.$t("cart.invalid_min_order_quantity_with_title", {
                title: item.title,
                count: item.metafields.stock_min,
              }),
            });
          }
        });
      }
    } else {
      // 담긴 상품이 없을 때
    }

    // console.log("ERRORS =====", errors);

    if (errors.length > 0) {
      cartStore.isValid = false;
      cartStore.errors = errors;
    } else {
      cartStore.isValid = true;
    }

    if (data) {
      const fastItems = getFastItems(data.items);
      const freeItems = getFreeItems(data.items);

      if (fastItems.length > 0) {
        isRoomCart = hasOnlyRoomLines(fastItems);

        cartStore.isRoomCart = isRoomCart;
      }

      cartStore.deliveryMethod["pkg-fast"].items = fastItems;
      cartStore.deliveryMethod["pkg-free"].items = freeItems;

      if (isRoomCart) {
        cartStore.minOrderSubtotal = roomMinOrderSubtotal;
      } else {
        cartStore.minOrderSubtotal = freshMinOrderSubtotal;
      }
    }

    const MIN_ORDER = isRoomCart ? roomMinOrderSubtotal : freshMinOrderSubtotal;

    // 배송타입 계산
    if (data) {
      let nextTotalSubtotal = 0;
      let nextTotalShippingRate = 0;
      const welcomeGiftSubtotal = data.items
        .filter((item) => isWelcomeGift(item))
        .reduce((a, b) => a + b.original_line_price, 0);

      cartStore.welcomeGiftSubtotal = welcomeGiftSubtotal;

      Object.keys(deliveryMethod).forEach((method) => {
        const methodData = deliveryMethod[method];
        let subtotalByMethod = 0;

        // 상품 합계 계산
        if (data && data.items && data.items.length > 0) {
          let itemsByMethod;

          if (method === "pkg-fast") {
            itemsByMethod = getFastItems(data.items).filter(
              (item) => get(quantities, `${item}.variant_id`, null) !== 0
            );
          } else if (method === "pkg-free") {
            itemsByMethod = getFreeItems(data.items);
          }

          subtotalByMethod = itemsByMethod.reduce(
            (a, b) => a + b.original_line_price,
            0
          );
        }

        // 상품 합계에 따른 배송비 부여
        let shippingRateByMethod = isRoomCart ? roomShippingRate : shippingRate;

        switch (method) {
          case "pkg-fast":
            shippingRateByMethod = isRoomCart ? roomShippingRate : shippingRate;

            // 최소 주문 금액이 초과 또는 금액이 없을 때
            if (
              subtotalByMethod - welcomeGiftSubtotal >= MIN_ORDER ||
              subtotalByMethod === 0
            ) {
              shippingRateByMethod = 0;
            }
            break;
          case "pkg-free":
            shippingRateByMethod = 0;
            break;
        }

        // UI Percentaage
        if (method === "pkg-fast") {
          const percentage =
            ((subtotalByMethod - welcomeGiftSubtotal) / MIN_ORDER) * 100;

          const progress =
            Math.floor(percentage) >= 100
              ? "100%"
              : `${Math.floor(percentage)}%`;

          methodData.ui.progress = progress;
        }

        // console.log("METHOD", method, "SUBTOTAL", subtotalByMethod);

        // 배송방법 금액
        methodData.subtotal = subtotalByMethod;
        methodData.shippingRate = shippingRateByMethod;

        // 장바구니 금액
        nextTotalSubtotal += subtotalByMethod;
        nextTotalShippingRate += shippingRateByMethod;
      });

      // console.log(
      //   deliveryMethod,
      //   "NEXT TOTAL",
      //   nextTotalSubtotal,
      //   "NEXT SHIPPING",
      //   nextTotalShippingRate
      // );

      cartStore.totalSubtotal = nextTotalSubtotal;
      cartStore.totalShippingRate = nextTotalShippingRate;
    }
  });
};

export const initGiftCard = () => {
  return Alpine.data("cartgift", () => ({
    gift: false,
    availableMessage: false,
    maxLength: 200,
    maxWidth: 10,
    maxLines: 6,
    lines: 0,
    lineError: false,
    message: "",
    isShippingHelpModalOpen: false,
    openShippingHelpModal() {
      this.isShippingHelpModalOpen = true;
      document.body.classList.add("overflow-hidden");
    },
    closeShippingHelpModal() {
      this.isShippingHelpModalOpen = false;
      document.body.classList.remove("overflow-hidden");
    },
    sampleModalOpen: false,
    openSampleModal() {
      this.sampleModalOpen = true;
      document.body.classList.add("overflow-hidden");
    },
    closeSampleModal() {
      this.sampleModalOpen = false;
      document.body.classList.remove("overflow-hidden");
    },
    filterEmoji(value) {
      const removedEmoji = value.replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ""
      );
      return removedEmoji;
    },
    handleBlur(event) {
      // console.log("handleBlur", event);
      event.preventDefault();
    },
    handleChange(event) {
      // console.log("handleBlur", event);
      event.preventDefault();
    },
    handleKeydown(event) {
      const lines = event.target.value.split("\n").length;

      if (event.which === 13 && lines === 6) {
        event.preventDefault();
        return false;
      }
    },
    handlePaste(event) {
      const lines = event.target.value.split("\n").length;
    },
    handleKeyup(event) {
      // console.log("handleBlur", event);
    },
    repositionCursor(D, textarea) {
      setTimeout(() => {
        textarea.selectionStart = D;
        textarea.selectionEnd = D;
      }, 1);
    },
    init() {
      this.$watch("message", () => {
        const value = this.filterEmoji(this.message);
        this.message = value.substring(0, this.maxLength);
      });

      Alpine.effect(() => {
        const cartData = Alpine.store("cart").data;

        if (!cartData || !cartData?.items || !cartData.items.length == 0) {
          return;
        }

        const lineItems = cartData.items;
        let invalidItemCount = 0;

        lineItems.forEach((item) => {
          if (item.product_type === "Gift Cards") {
            return invalidItemCount++;
          }
          if (item?.tags && item.tags.includes("direct_delivery")) {
            return invalidItemCount++;
          }
        });

        if (invalidItemCount === lineItems.length) {
          return (this.availableMessage = false);
        }

        this.availableMessage = true;
      });

      this.$watch("gift", (value) => {
        if (value) {
          const cartData = Alpine.store("cart").data;
          const lineItems = cartData.items;
          let invalidItemCount = 0;

          lineItems.forEach((item) => {
            if (item.product_type === "Gift Cards") {
              return invalidItemCount++;
            }
            if (item?.tags && item.tags.includes("direct_delivery")) {
              return invalidItemCount++;
            }
          });

          if (invalidItemCount === lineItems.length) {
            return (this.availableMessage = false);
          }

          this.availableMessage = true;
        }
      });
    },
  }));
};
